import React from "react";
import Header from "../Header/Header";
import { BiMessage } from "react-icons/bi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Paragraph from "../Paragraph/Paragraph";
import { dotNotationByGivenNum, getFormatDate } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { deleteBlogById } from "../../utils/services";
const BlogCard = (props) => {
  const navigate = useNavigate();
  const {
    postedBy,
    userImage,
    blogImage,
    date,
    blogTitle,
    blogDesc,
    onClick,
    style,
    blogId,
    onDeleteBlogSuccess,
    commentCount,
  } = props;

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleBlogEdit = () => {
    navigate(`/edit-blog/${blogId}`);
  };
  const handleDeleteBlogById = async () => {
    try {
      const { status } = await deleteBlogById(blogId);
      if (status) {
        onDeleteBlogSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div style={style} className={"blog_post"}>
        <div className={"blog_options"}>
          <BiDotsVerticalRounded className={"dots_vertical"} />
          <div className={"dropdown_wrapper"}>
            <button onClick={handleBlogEdit}>Edit</button>
            <button onClick={handleDeleteBlogById}>Delete</button>
          </div>
        </div>
        <div>
          <Paragraph className={"user_title"}>{postedBy}</Paragraph>
          <img className={"user_photo"} src={userImage} alt="" />
          <img
            // onClick={onClick}
            className={"blog_thumb"}
            src={blogImage}
            alt=""
          />
          <div className={"content_wrapper"}>
            <div className={"actions"}>
              <div className={"flex_actions"}>
                <Paragraph fontSize={14} fontWeight="light">
                  {" "}
                  <BiMessage /> {commentCount} Comments
                </Paragraph>
                {/* <Paragraph
                  onClick={() => console.log(blogId, "blogId")}
                  fontSize={14}>
                  <AiOutlineShareAlt /> Share
                </Paragraph> */}
              </div>
              <Paragraph fontSize={14} fontWeight="light" className={"date"}>
                {getFormatDate(date)}
              </Paragraph>
            </div>
            <Header variant="h3" className={"title"} fontWeight="semi-bold">
              {dotNotationByGivenNum(blogTitle, 35)}
            </Header>
            <p
              dangerouslySetInnerHTML={createMarkup(blogDesc)}
              className={"desc"}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
