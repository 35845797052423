import React from "react";
import LogoBanner from "../../shared-ui/LogoBanner/LogoBanner";
import BlogForm from "../BlogForm/BlogForm";
import { useNavigate } from "react-router-dom";

const CreateBlog = () => {
  const navigate = useNavigate();
  const logoClickHandler = () => {
    // navigate to back
    navigate(-1);
  };
  return (
    <>
      <LogoBanner hover={true} onClick={logoClickHandler} />
      <BlogForm type={"create"} />
    </>
  );
};

export default CreateBlog;
