import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../utils/services";
import BlogForm from "../BlogForm/BlogForm";

const EditBlog = () => {
  let { id } = useParams();
  const [formData, setFormData] = React.useState({});
  useEffect(() => {
    if (id) {
      // fetch blog by id
      fetchBlogById(id);
    }
  }, [id]);
  const fetchBlogById = async (id) => {
    try {
      const { data } = await getBlogById(id);
      if (data) {
        const resData = {
          ...data,
          id,
        };
        setFormData(resData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {Object.entries(formData).length > 0 && (
        <BlogForm formData={formData} type="edit" />
      )}
    </>
  );
};

export default EditBlog;
