import React, { useEffect } from "react";
import addIcon from "./../../assets/images/icons/addIcon.png";

const FileInputElement = ({ previewImage, onFileChange, label = "Label" }) => {
  const [preview, setPreview] = React.useState("");
  const [image, setImage] = React.useState(null);
  const fileInputRef = React.useRef(null);

  const emitFileClickHandler = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileUplaod = (e) => {
    const file = e.target.files[0];
    //  check if file is an image
    if (file && file.type.match("image.*")) {
      setImage(file);
      onFileChange(file);
    } else {
      setImage(null);
    }
  };
  useEffect(() => {
    if (image) {
      // get the image url
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = function () {
        setPreview(reader.result);
      };
      console.log("image", image);
    } else {
    }
  }, [image]);

  return (
    <div className="upload_input_wrapper">
      <span className="parent_label">
        <p>{label}</p>
      </span>
      <div onClick={emitFileClickHandler} className="upload_input">
        {(preview || previewImage) && (
          <img
            className="preview_image"
            src={(preview || previewImage)}
            alt="preview"
          />
        )}
        {!(preview || previewImage) && (
          <img className="add_icon" src={addIcon} alt="addIcon" />
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileUplaod}
        accept="image/*"
      />
    </div>
  );
};

export default FileInputElement;
