import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

const CustomSelect = (props) => {
  const {
    label,
    placeholder = "Select",
    Options = [],
    type,
    onSelect,
    customClass,
    name,
    value = "",
    style = {},
    autoComplete,
  } = props;
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState(false);

  const hideOption = () => {
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  const selectHanlder = (value) => {
    onSelect(value);
  };

  useEffect(() => {
    if (value) {
      // find the value in the options
      const { title } = Options.find((option) => option.id === value);
      setSelectedValue(title);
    }
  }, [value]);

  return (
    <div
      className={`custom_select_wrapper ${
        type === "light" ? "light" : ""
      } ${customClass}`}
      style={style}>
      <label htmlFor="">{label}</label>
      <div
        style={{ marginTop: label?.length > 0 ? 4 : 0 }}
        className={"select_area"}>
        {/* <div className={"place_holder"}>
          {selectedValue.length > 0 ? "" : placeholder}
        </div> */}
        <input
          onBlur={hideOption}
          // onFocus={() => setOpen(true)}
          onClick={() => setOpen(!open)}
          value={selectedValue}
          type="text"
          placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
        />
        <BiChevronDown />
        {open && (
          <div className={"select_selection"}>
            {Options?.map((v, i) => (
              <p
                key={i}
                onClick={() => {
                  setSelectedValue(v.title);
                  selectHanlder(v.id);
                }}>
                {v.title}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
