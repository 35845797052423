import React from "react";
import Routing from "./Routing/Routing";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

// Navigate Component
// Navigate is a new component which handles route more intelligently and redirects them
// Let's say we want all our unknown routes to the home page

const App = () => {
  return (
    <Router>
      <Routing />
    </Router>
  );
};

export default App;
