import React from "react";
import LoginForm from "../../shared-ui/LoginForm/LoginForm";
import LogoBanner from "../../shared-ui/LogoBanner/LogoBanner";

const Home = () => {
  return (
    <>
      {/* HEADER */}
      <LogoBanner />
      <LoginForm />
    </>
  );
};

export default Home;
