import React, { useEffect } from "react";
import { BsSearch } from "react-icons/bs";

const InputElement = ({
  label,
  name,
  type = "text",
  placeholder = "",
  onInputChange,
  value = "",
  error,
  showIcon = false,
}) => {
  const [input, setInput] = React.useState("");
  const handleInputaChange = (e) => {
    const { value } = e.target;
    setInput(value);
    onInputChange && onInputChange(e);
  };

  useEffect(() => {
    if (value) {
      setInput(value);
    }
  }, [value]);

  return (
    <div className="input_wrapper_main">
      {showIcon && (
        <p className="icon center">
          <BsSearch />
        </p>
      )}
      <p htmlFor="" className="label">{label}</p>
      <input
        value={input}
        onChange={handleInputaChange}
        name={name}
        type={type}
        placeholder={placeholder}
      />
      {/* ERROR MESSAGE SPACE */}
      {/* {error && (
        <span id={"errorMessage"} className={"errorMessage"} aria-live="polite">
          The email is invalid
        </span>
      )} */}
    </div>
  );
};

export default InputElement;
