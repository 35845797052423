import React from "react";
import logo from "./../../assets/images/logo/logo.svg";


const LogoBanner = ({ hover = false, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`home_page_header ${hover && "hover_class"}`}>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
    </div>
  );
};

export default LogoBanner;
