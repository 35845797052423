import React, { useState, useEffect } from "react";
import InputElement from "../../shared-ui/InputElement/InputElement";
import "./_BlogForm.scss";
import Button from "../../shared-ui/Button/Button";
import Section from "../../shared-ui/Section/Section";
import CustomSelect from "../../shared-ui/CustomSelect/CustomSelect";
import FileInputElement from "../../shared-ui/FileInputElement/FileInputElement";
import { useNavigate } from "react-router-dom";
import RTEditor from "../../shared-ui/RTEditor/RTEditor";
import {
  createBlog,
  editBlogById,
  getBlogCategories,
  uploadFileAPI,
} from "../../utils/services";

const BlogForm = ({ formData, type }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authorImage, setAuthorImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [editorState, setEditorState] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [blogCategoryOptions, setBlogCategoryOptions] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const fileChangeHandler = (val, type) => {
    if (val) {
      if (type === "authorImage") {
        setAuthorImage(val);
      } else {
        setCoverImage(val);
      }
    }
  };

  const getAllCategories = async () => {
    try {
      const {
        status,
        data: { items },
      } = await getBlogCategories();
      if (status) {
        setBlogCategoryOptions(items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateBack = () => {
    // navigate to back
    navigate(-1);
  };

  useEffect(() => {
    // EXECUTE WHEN FORM DATA RECEIVED
    if (formData && type === "edit") {
      const {
        title,
        content,
        blogPhoto,
        fullName,
        photo: authorImage,
      } = formData || {};
      // ONLY SET INPUT VALUES
      const coverImage = blogPhoto?.[0]?.url;
      setEditorState(content);
      setAuthorName(fullName);
      setBlogTitle(title);
      setAuthorImage(authorImage);
      setCoverImage(coverImage);
    }
  }, [formData]); // changing in formData will trigger this effect

  useEffect(() => {
    if (blogCategoryOptions.length > 0 && formData) {
      const { id } = blogCategoryOptions.find(
        (item) => item.title === formData.blogCategory.title
      );
      setBlogCategory(id);
    }
  }, [formData, blogCategoryOptions]); // changing blogCategoryOptions will trigger this effect

  const getPreviewImage = (image) => {
    if (image) {
      return image;
    } else {
      return null;
    }
  };

  const getEditorDefaultValue = (content) => {
    if (content && type === "edit") {
      return content;
    } else {
      return "";
    }
  };

  const handleCKEditorChange = (e) => {
    setEditorState(e);
  };

  const formSubmitHanlder = async () => {
    const payload = {
      fullName: authorName,
      blogCategoryId: blogCategory,
      title: blogTitle,
      content: editorState,
    };
    const blogId = formData?.id;
    blogFormSubmitHandler(payload, blogId); // id is optional for create blog request but required for edit blog request
  };

  const uploadFile = async (file, type) => {
    if (typeof file === "string") {
      // if file is already uploaded
      return file;
    } else {
      // if file is not uploaded
      try {
        setIsLoading(true);
        const paylad = new FormData();
        paylad.append("file", file);
        const {
          data: { url },
        } = await uploadFileAPI(paylad);
        setIsLoading(false);
        return url;
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  const blogFormSubmitHandler = async (payload, id) => {
    // id is optional for create blog request else it is required for edit blog request
    // UPLOAD FILES ON SERVER
    let tempImageURL = await uploadFile(authorImage);
    let tempCoverImageURL = await uploadFile(coverImage);
    try {
      let resStatus = false;
      setIsLoading(true);
      const bodyPayload = {
        ...payload,
        photo: tempImageURL,
        url: [tempCoverImageURL],
      };

      if (type === "edit") {
        // EDIT BLOG
        const { status } = await editBlogById(id, bodyPayload);
        resStatus = status;
      }
      if (type === "create") {
        // CREATE BLOG
        const { status } = await createBlog(bodyPayload);
        resStatus = status;
      }
      if (resStatus) {
        navigateBack();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Section>
        <div className="first_row_wrapper">
          {/* IMAGE UPLOAD */}
          <div className="file_input_wrapper">
            <FileInputElement
              previewImage={type === "edit" && getPreviewImage(authorImage)}
              onFileChange={(e) => fileChangeHandler(e, "authorImage")}
              label="Author Image"
            />
          </div>
          <div className="name_input_wrapper">
            <InputElement
              label="Author Name"
              placeholder="Enter Name"
              name="fullName"
              value={authorName}
              onInputChange={(e) => setAuthorName(e.target.value)}
            />
          </div>
        </div>
        {/* IMAGE UPLOAD */}
        <div className="cover_file_upload_wrapper">
          <FileInputElement
            previewImage={type === "edit" && getPreviewImage(coverImage)}
            onFileChange={(e) => fileChangeHandler(e, "coverImage")}
            label="Cover Image"
          />
        </div>
        <CustomSelect
          placeholder="Category"
          Options={blogCategoryOptions}
          label="Select Category"
          name="category"
          autoComplete={"off"}
          value={blogCategory}
          onSelect={(val) => {
            setBlogCategory(val);
          }}
        />
        <InputElement
          label="Blog Title"
          placeholder="Add Title"
          name="title"
          value={blogTitle}
          onInputChange={(e) => setBlogTitle(e.target.value)}
        />
        {/* CK EDITOR */}
        <RTEditor
          label={"Blog Content"}
          onEditorStateChange={handleCKEditorChange}
          defaultValue={getEditorDefaultValue(editorState)}
        />
        {/* ************************ */}
        <div className="buttonWrapper">
          <Button
            backgroundColor="#fff"
            color="#000"
            hoverLight={true}
            border={`solid 1px #000`}
            onClick={navigateBack}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            hover={true}
            onClick={formSubmitHanlder}>
            {type === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </Section>
    </>
  );
};

export default BlogForm;
