// Function to show temporary image from Local storage to a DOM element
export const showTempImgFromBaseURL = (file, className) => {
  if (file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64dUrl = reader.result;
      document.querySelector(className).src = base64dUrl;
    };
  }
};

// get format date from date string
export const getFormatDate = (date) => {
  const newDate = new Date(date);
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${newDate.getDate()}-${
    monthNames[newDate.getMonth() + 1]
  }-${newDate.getFullYear()}`;
};

// check string word length if it is more than given words then return words with dot notation
export const dotNotationByGivenNum = (str, maxCount) => {
  if (str.length > maxCount) {
    return str.slice(0, maxCount) + "...";
  }
  return str;
};
