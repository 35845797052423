import React, { useRef, useState } from "react";
import Cookies from "universal-cookie";
import { signInAdmin } from "../../utils/services";
import Button from "../Button/Button";
import FormWrapper, { formMethodsObject } from "../FormWrapper/FormWrapper";
import InputElement from "../InputElement/InputElement";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = formMethodsObject.getFormData();
    signInUserHanlder(formData);
  };
  const signInUserHanlder = async (formData) => {
    try {
      setIsLoading(true);
      const { data, status } = await signInAdmin(formData);
      if (data && status) {
        // add token to cookies
        cookies.set("token", data);
        navigate("/blogs");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // alert(error.message);
      console.log(error);
    }
  };
  return (
    <>
      {/* <Section> */}
      <div className="login_wrapper">
        <FormWrapper form={formRef}>
          <InputElement
            error={"false"}
            placeholder="Your Email"
            name={"email"}
          />
          <InputElement placeholder="Your Password" name={"password"} />
        </FormWrapper>
        <Button isLoading={isLoading} onClick={handleFormSubmit}>
          Login
        </Button>
      </div>
      {/* </Section> */}
    </>
  );
};

export default LoginForm;
