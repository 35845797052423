import React, { useState, useEffect } from "react";
import BlogCard from "../../shared-ui/blogCard/blogCard";
import "./_ShowBlogs.scss";
import Section from "../../shared-ui/Section/Section";
import CustomSelect from "../../shared-ui/CustomSelect/CustomSelect";
import InputElement from "../../shared-ui/InputElement/InputElement";
import logo from "./../../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";
import InfiniteScroll from "../../shared-ui/InfiniteScroll/InfiniteScroll";
import { getBlogCategories, getBlogs } from "../../utils/services";
import notFound from "./../../assets/images/icons/not-found.png";
import loadingGif from "./../../assets/images/loaders/loaderGif2.gif";

const ShowBlogs = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [blogCategory, setBlogCategory] = useState("");
  const [blogCategoryOptions, setBlogCategoryOptions] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteBlogSuccess, setDeleteBlogSuccess] = useState({
    id: "",
    status: false,
  });
  const [finalLimit, setFinalLimit] = useState(1);

  useEffect(() => {
    // FETCH INITIALL BLOGS
    fetchInitiallBlogs();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (page <= finalLimit && page !== 1) {
      fetchPaginatedBlogs();
    }
  }, [page]);

  useEffect(() => {
    if (deleteBlogSuccess.status) {
      // filter out the deleted blog
      const filteredBlogs = blogsData.filter(
        (blog) => blog?.id !== deleteBlogSuccess?.id
      );
      setBlogsData(filteredBlogs);
    }
  }, [deleteBlogSuccess]);

  const getAllCategories = async () => {
    try {
      const {
        status,
        data: { items },
      } = await getBlogCategories();
      if (status) {
        setBlogCategoryOptions(items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPaginatedBlogs = async () => {
    try {
      const qs = `?page=${page}&limit=7`;
      const { status, data } = await getBlogs(qs);
      if (status) {
        setBlogsData([...blogsData, ...data?.items]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInitiallBlogs = async () => {
    try {
      setIsLoading(true);
      const qs = `?page=1&limit=7`;
      const { status, data } = await getBlogs(qs);
      if (status) {
        setBlogsData(data?.items);
        setFinalLimit(data?.meta?.totalPages);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSelectChange = (e) => {
    setBlogCategory(e);
  };

  const getFilteredBlogData = () => {
    // CHECK IF BOTH NOT EXIST
    if (!searchInput && !blogCategory) {
      return blogsData;
    }
    // CHECK IF SEARCH INPUT EXIST
    if (searchInput && !blogCategory) {
      return blogsData.filter((blog) =>
        blog.title.toLowerCase().includes(searchInput.toLowerCase())
      );
    }
    // CHECK IF BLOG CATEGORY EXIST
    if (!searchInput && blogCategory) {
      return blogsData.filter((blog) => blog.category === blogCategory);
    }
    // CHECK IF BOTH EXIST
    if (searchInput && blogCategory) {
      return blogsData.filter(
        (blog) =>
          blog.title.toLowerCase().includes(searchInput.toLowerCase()) &&
          blog.category === blogCategory
      );
    }
  }; // END OF FILTERED BLOGS BY TITLE OR CATEGORY

  return (
    <>
      {/* HEADER */}
      <div className="blogMain">
        <div className="blogMain__header">
          <div className="blogMain__logo">
            <img src={logo} alt="" />
          </div>
          <div className="blogMain__nav">
            <Link to="/blog/create">Create Blog</Link>
          </div>
        </div>
      </div>
      {/* SHOW ALL BLOGS */}
      <Section>
        <div className={"searchTile"}>
          <div className="search_input_wrapper">
            <InputElement
              showIcon={true}
              value={searchInput}
              onInputChange={handleSearchInput}
              type="text"
              placeholder="Search"
            />
          </div>
          <CustomSelect
            placeholder="Category"
            Options={blogCategoryOptions}
            onSelect={handleSelectChange}
            customClass={"searchSelect"}
          />
        </div>

        {/* SHOW LOADER IF FETCHING */}
        {isLoading && (
          <div className="loader_wrapper">
            <img className="loader_gif_item" src={loadingGif} alt="" />
          </div>
        )}

        {/* BLOGS NOT FOUND  */}
        {!isLoading && !getFilteredBlogData()?.length && (
          <div className="noBlogsFound center">
            <img src={notFound} alt="" />
          </div>
        )}

        {/* BLOGS */}
        <div className={"blog_wrapper"}>
          {!isLoading && (
            <InfiniteScroll
              length={blogsData?.length ?? 0}
              inverse={false}
              page={page}
              setPage={setPage}>
              {getFilteredBlogData()?.map((blog) => (
                <div key={blog.id}>
                  {console.log("blog", blog)}
                  <BlogCard
                    postedBy={`Posted by ${" "} ${blog?.fullName || ""}`}
                    userImage={blog?.photo}
                    blogImage={blog?.blogPhoto?.[0]?.url}
                    date={blog?.createdAt}
                    blogTitle={blog?.title}
                    blogDesc={blog?.content}
                    key={blog?.id}
                    blogId={blog?.id}
                    commentCount={blog?.commentCount}
                    onDeleteBlogSuccess={() => {
                      setDeleteBlogSuccess({
                        id: blog?.id,
                        status: true,
                      });
                    }}
                  />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </Section>
    </>
  );
};

export default ShowBlogs;
