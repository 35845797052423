import React, { Component, Fragment, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import Paragraph from "../Paragraph/Paragraph";

const RTEditor = ({ onEditorStateChange, defaultValue, label }) => {
  const onChange = (event, editor) => {
    const data = editor.getData();
    onEditorStateChange(data);
  };

  const onBlur = (event, editor) => {
    // console.log("Blur.", editor);
  };

  const onFocus = (event, editor) => {
    // console.log("Focus.", editor);
  };

  const onReady = (editor) => {
    // console.log("Editor is ready to use!", editor);
  };

  const editorConfiguration = {
    height: 500,
    placeholder: "Enter Content",
  };
  return (
    <>
      <Paragraph>{label}</Paragraph>
      <div className="editor">
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={defaultValue ? defaultValue : ""}
          onReady={onReady}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    </>
  );
};

export default RTEditor;
