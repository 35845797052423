import { del, get, patch, post } from "./httpService";

const SERVICE_URLS = {
  getBlogs: (qs) => `/blogs${qs}`,
  getBlogById: (id) => `/blogs/${id}`,
  createBlog: () => `/blogs`,
  editBlogById: (id) => `/blogs/${id}`,
  deleteBlogById: (id) => `/blogs/${id}`,
  signInAdmin: () => `admin/v1/admin-auth/signin`,
  getBlogCategories: () => `/blog-category?page=1&limit=50`,
  uploadFileAPI: () => `/file-handle/file-upload`,
};

export const getBlogs = (qs) => get(SERVICE_URLS.getBlogs(qs));
export const getBlogById = (id) => get(SERVICE_URLS.getBlogById(id));
export const deleteBlogById = (id) => del(SERVICE_URLS.deleteBlogById(id));
export const getBlogCategories = () => get(SERVICE_URLS.getBlogCategories());
export const signInAdmin = (body) => post(SERVICE_URLS.signInAdmin(), body);
export const createBlog = (body) => post(SERVICE_URLS.createBlog(), body);
export const uploadFileAPI = (body) => post(SERVICE_URLS.uploadFileAPI(), body);
export const editBlogById = (id, body) =>
  patch(SERVICE_URLS.editBlogById(id), body);
